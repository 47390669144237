import React, { Suspense, lazy } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import loaderImg from '../assets/images/loader.gif';
import bird from "../assets/images/bird.gif";
import FollowRequests from "../View/Member/MemberList/FollowRequests";

const OfflineBooking = lazy(() => import("../View/Member/OfflineBooking"));

const LoginWithPass = lazy(() =>
  import("../View/Elements/Forms/LoginWithPass")
);

const OfflineListing = lazy(() =>
  import("../View/Member/OfflineBooking/OfflineListing")
);

const MainLayout = lazy(() => import("../View/MainLayout"));

const HillywoodPlanPayment = lazy(() =>
  import("../View/Elements/HillywoodPlanPayment")
);

const HillywoodPaymentResponse = lazy(() =>
  import("../View/Elements/HillywoodPaymentResponse")
);
const HillywoodFreePlanPaymentResponse = lazy(() =>
  import("../View/Elements/HillywoodFreePlanPaymentResponse")
);

const HotelRedirect = lazy(() => import("../View/Hotel/HotelRedirect"));

const UniqueHomeRedirect = lazy(() =>
  import("../View/Hotel/UniqueHomeRedirect")
);

const WebViewError = lazy(() =>
  import("../View/Elements/WebViewError/WebViewError")
);
const AppLink = lazy(() => import("../View/AppLink"));
const FlightTripSearch = lazy(() => import("../View/Search/FlightTripSearch"));
const FlightPaymentVerify = lazy(() =>
  import("../View/Flight/FlightPaymentVerify")
);
const HillypixPaymentVerify = lazy(() =>
  import("../View/Elements/HillypixPaymentVerify")
);
const UnderMaintainence = lazy(() =>
  import("../View/Elements/UnderMaintainence")
);

const VendorBookingDetail = lazy(() =>
  import("../View/Member/VendorReceivedOrders/VendorBookingDetail")
);

const PackageBookingDetail = lazy(() =>
  import("../View/Member/VendorReceivedOrders/PackageBookingDetail")
);

const BookService = lazy(() => import("../View/Services/Booking/BookService"));
const SuccessBookingService = lazy(() =>
  import("../View/Services/Booking/Success")
);

const TaxiPackageSuccess = lazy(() =>
  import("../View/Services/Booking/TaxiPackageSuccess")
);

const UserAuth = lazy(() => import("./UserAuth"));
const VendorAuth = lazy(() => import("./VendorAuth"));
// const Header = lazy(() => import("../View/Elements/Header/Index"));
// const Footer = lazy(() => import("../View/Elements/Footer/Index"));
const ScrollToTop = lazy(() => import("../View/Elements/scrollToTop"));
const Home = lazy(() => import("../View/Home/Index"));
const About = lazy(() => import("../View/Cms/About/Index"));
// const Authors = lazy(() => import("../View/Cms/Authors/Index"));
const Offers = lazy(() => import("../View/Cms/Offers/Index"));
const Faq = lazy(() => import("../View/Cms/Faq/Index"));
const WebVIewFaq = lazy(() => import("../View/Cms/WebView/Faq/Index"));
const WebVIewAbout = lazy(() => import("../View/Cms/WebView/About/Index"));
const WebViewPolicy = lazy(() =>
  import("../View/Cms/WebView/PrivacyPolicy/Index")
);
const WebViewTermConditions = lazy(() =>
  import("../View/Cms/WebView/TermsConditions/Index")
);
const Careers = lazy(() => import("../View/Cms/Career/Index"));
const Contact = lazy(() => import("../View/Cms/Contact/Index"));
const PrivacyPolicy = lazy(() => import("../View/Cms/PrivacyPolicy/Index"));
const LiamCoinsPolicy = lazy(() => import("../View/Cms/LiamCoinsPolicy/Index"));
const TermsConditions = lazy(() => import("../View/Cms/TermsConditions/Index"));
const Refundsandcancellations = lazy(() =>
  import("../View/Cms/Refundsandcancellations/Index")
);
const BecomeAPartner = lazy(() => import("../View/Cms/BecomeAPartner/Index"));
const BookingTips = lazy(() => import("../View/Cms/BookingTips/index"));
const BookingDetails = lazy(() => import("../View/Cms/BookingDetail/Index"));

const BookingCancellation = lazy(() =>
  import("../View/Cms/BookingCancellation/Index")
);
const CancellationStatus = lazy(() =>
  import("../View/Cms/CancellationStatus/Index")
);

const FlightListing = lazy(() => import("../View/Flight/FlightListing"));
const VerifyEmail = lazy(() => import("../View/Cms/VerifyEmail/VerifyEmail"));
const FlightDetail = lazy(() => import("../View/Flight/FlightDetail"));
const FlightBookingHistory = lazy(() =>
  import("../View/Flight/FlightBookingHistory")
);
const FlightBookingDetail = lazy(() =>
  import("../View/Flight/FlightBookingDetail")
);

const GetFlightBookingDetail = lazy(() =>
  import("../View/Flight/GetFlightBookingDetail")
);

const FlightCancelResult = lazy(() =>
  import("../View/Flight/FlightCancelResult")
);

const HotelListing = lazy(() => import("../View/Hotel/HotelListing"));
const HotelDetail = lazy(() => import("../View/Hotel/HotelDetail"));
const HotelBookingHistory = lazy(() =>
  import("../View/Hotel/HotelBookingHistory")
);
const HotelBookingDetail = lazy(() =>
  import("../View/Hotel/HotelBookingDetail")
);
const HotelBookReviewCard = lazy(() =>
  import("../View/Hotel/Components/HotelBookReviewCard")
);
const HotelCancelResult = lazy(() => import("../View/Hotel/HotelCancelResult"));

const TripPlanningSearch = lazy(() =>
  import("../View/Services/Trip/Book/TripPlanningSearch")
);
const TripPlanningDetail = lazy(() =>
  import("../View/Services/Trip/Book/TripPlanningDetail")
);

const UniqueHomeListing = lazy(() =>
  import("../View/Services/UniqueHome/Book/UniqueHomeListing")
);

const TourListing = lazy(() =>
  import("../View/Services/Tour/Book/TourListing")
);

const TaxiListing = lazy(() =>
  import("../View/Services/Taxi/Book/TaxiListing")
);

const TaxiPackageListing = lazy(() =>
  import("../View/Services/Taxi/Book/TaxiPackageListing")
);

const TaxiPackageBookingListing = lazy(() =>
  import("../View/Services/Taxi/Book/TaxiPackageBookingListing")
);

const MultiServicesListing = lazy(() =>
  import("../View/Services/MultiServices/Book/MultiServicesListing")
);

const SearchResult = lazy(() => import("../View/Services/SearchResult"));
const SearchDetail = lazy(() =>
  import("../View/Services/SearchResult/SearchDetail")
);

const NewsfeedDetail = lazy(() =>
  import("../View/Member/Newsfeed/NewsfeedDetail")
);
const NotFound = lazy(() => import("../View/Cms/Error/404"));

const MemberPanel = lazy(() => import("../View/Member/MemberPanel"));
const Newsfeed = lazy(() => import("../View/Member/Newsfeed/Index"));
const ViewUserProfile = lazy(() =>
  import("../View/Member/Newsfeed/ViewUserProfile")
);
// const MyPost = lazy(() => import("../View/Member/MyPost/Index"));

const VendorReceivedOrders = lazy(() =>
  import("../View/Member/VendorReceivedOrders/Index")
);

const AllBookings = lazy(() =>
  import("../View/Services/AllListings/AllBookings")
);

const BookingUniqueHomeListing = lazy(() =>
  import("../View/Services/UniqueHome/Host/BookingUniqueHomeListing")
);

const FlightBookings = lazy(() => import("../View/Flight/FlightBookings"));
const FlightPayment = lazy(() => import("../View/Flight/FlightPayment"));
const HotelPayment = lazy(() => import("../View/Hotel/HotelPaymentStatus"));
const HotelBookings = lazy(() => import("../View/Hotel/HotelBookings"));

const AllListings = lazy(() =>
  import("../View/Services/AllListings/AllListings")
);
const ManageUniqueHomes = lazy(() =>
  import("../View/Services/UniqueHome/Host/ManageUniqueHomes")
);
const CalendarManagementUniqueHomes = lazy(() =>
  import("../View/Services/UniqueHome/Host/CalendarManagementUniqueHomes")
);
const CalendarManagementTour = lazy(() =>
  import("../View/Services/Tour/Host/CalendarManagementTour")
);
const CalendarManagementMultiServices = lazy(() =>
  import("../View/Services/MultiServices/Host/CalendarManagementMultiServices")
);
const AddUniqueHome = lazy(() =>
  import("../View/Services/UniqueHome/Host/AddUniqueHome")
);
const AddTaxi = lazy(() => import("../View/Services/Taxi/Host/AddTaxi"));
const AddMultiServices = lazy(() =>
  import("../View/Services/MultiServices/Host/AddMultiServices")
);

const ManageTaxi = lazy(() => import("../View/Services/Taxi/Host/ManageTaxi"));
const ManageTour = lazy(() => import("../View/Services/Tour/Host/ManageTour"));
const ManageMultiServices = lazy(() =>
  import("../View/Services/MultiServices/Host/ManageMultiServices")
);

const WishlistServices = lazy(() => import("../View/Member/Wishlist"));

const BuyCoin = lazy(() => import("../View/Member/Wallet/BuyCoin"));
const TransferCoins = lazy(() => import("../View/Member/Wallet/TransferCoins"));
const RedeemCoins = lazy(() => import("../View/Member/Wallet/RedeemCoins"));
const ViewRedeemCoinRequests = lazy(() =>
  import("../View/Member/Wallet/ViewRedeemCoinRequests")
);
const WalletTransactions = lazy(() =>
  import("../View/Member/Wallet/WalletTransactions")
);

const BusinessChain = lazy(() =>
  import("../View/Member/BusinessChain/BusinessChain")
);
const BusinessMyChain = lazy(() =>
  import("../View/Member/BusinessChain/BusinessMyChain")
);
const CreateBusinessGroup = lazy(() =>
  import("../View/Member/BusinessChain/CreateBusinessGroup")
);
const AllMembers = lazy(() => import("../View/Member/MemberList/Index"));
const Followers = lazy(() => import("../View/Member/MemberList/Followers"));
const Following = lazy(() => import("../View/Member/MemberList/Following"));
const Notification = lazy(() => import("../View/Member/NotifIcation/Index"));

const Profile = lazy(() => import("../View/Member/Profile/Profile/Index"));
const ReferEarn = lazy(() => import("../View/Member/ReferEarn/Index"));
const ChangeEmail = lazy(() =>
  import("../View/Member/Profile/ChangeEmail/Index")
);
const ChangePassword = lazy(() =>
  import("../View/Member/Profile/ChangePassword/Index")
);
const ChangePhoneNumber = lazy(() =>
  import("../View/Member/Profile/ChangePhoneNumber/Index")
);
const ManageTrip = lazy(() => import("../View/Services/Trip/Host/ManageTrip"));
const TripDetail = lazy(() => import("../View/Services/Trip/Host/TripDetail"));
const AddUniqueHomeTwo = lazy(() =>
  import("../View/Services/UniqueHome/Host/AddUniqueHomeTwo")
);
const AddServiceListTwo = lazy(() =>
  import("../View/Services/Components/AddServiceListTwo")
);
const PostResumeForm = lazy(() =>
  import("../View/Cms/Career/Components/PostResumeForm")
);

const AddServiceListThree = lazy(() =>
  import("../View/Services/Components/AddServiceListThree")
);

const AddTaxiDocuments = lazy(() =>
  import("../View/Services/Taxi/Host/AddTaxiDocuments")
);
const AddPrivateTour = lazy(() =>
  import("../View/Services/Tour/Host/AddPrivateTour")
);
const AddFixedDepartureTour = lazy(() =>
  import("../View/Services/Tour/Host/AddFixedDepartureTour")
);
const AddMultiServicesCategory = lazy(() =>
  import("../View/Services/MultiServices/Host/AddMultiServicesCategory")
);

const ChatLogout = lazy(() => import("../View/ChatLogout"));

const Loader = () => {
  return (
    <div className="loading_icon_full">
      <div>
        <img src={bird} alt="Bird" style={{ width: "200px" }} />
      </div>
    </div>
  );
};

const Routing = () => {
  return (
    <>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        {/* <Header /> */}
        <ScrollToTop />
        <main>
          <Routes>
            <Route path="/appLink" element={<AppLink />} />
            <Route path="/chat-logout" element={<ChatLogout />} />
            <Route
              path="/register"
              element={<Navigate to="/?register=true" />}
            />
            <Route
              path="/hillywood-payment"
              element={<HillywoodPlanPayment />}
            />
            <Route
              path="/hillywood-payment/response"
              element={<HillywoodPaymentResponse />}
            />
            <Route
              path="/hillywood-free-plan-payment/response"
              element={<HillywoodFreePlanPaymentResponse />}
            />
            <Route
              path="/WebViewFlight/flight_search"
              element={<UnderMaintainence />}
            />

            <Route path="/faq" element={<WebVIewFaq />} />
            <Route path="/about" element={<WebVIewAbout />} />
            <Route path="/privacy_and_policy" element={<WebViewPolicy />} />
            <Route
              path="/terms_n_conditions"
              element={<WebViewTermConditions />}
            />

            <Route
              path="/WebViewHotel/hotel_search"
              element={<UnderMaintainence />}
            />
            <Route
              path="/CCPay/ccavRequestHandler.php"
              element={<UnderMaintainence />}
            />
            <Route
              path="/WebView/business_chain_app"
              element={<UnderMaintainence />}
            />
            <Route
              path="/webview-flight-booking-verify"
              element={<FlightPaymentVerify />}
            />
            <Route
              path="/webview-hillypix-booking-verify"
              element={<HillypixPaymentVerify />}
            />
            <Route path="/webview/error" element={<WebViewError />} />
            <Route
              path="/webview/flight-listings"
              element={<FlightListing />}
            />
            <Route path="/webview-flight-detail" element={<FlightDetail />} />
            <Route
              path="/webview-flight-booking-payment/response"
              element={<FlightPayment />}
            />
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="/loginwithpassword" element={<LoginWithPass />} />
              <Route path="/aboutus" element={<About />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/frequentlyaskedquestion" element={<Faq />} />
              <Route path="/career" element={<Careers />} />
              {/* <Route path="/authors" element={<Authors/>} /> */}
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/PostResumeForm" element={<PostResumeForm />} />
              <Route path="/privacyandpolicy" element={<PrivacyPolicy />} />
              <Route path="/liamcoinspolicy" element={<LiamCoinsPolicy />} />
              <Route path="/termsandconditions" element={<TermsConditions />} />
              <Route
                path="/refundsandcancellations"
                element={<Refundsandcancellations />}
              />

              <Route path="/becomeapartner" element={<BecomeAPartner />} />
              <Route path="/bookingtips" element={<BookingTips />} />
              <Route path="/bookingdetails" element={<BookingDetails />} />
              <Route
                path="/vendor-booking-details"
                element={<VendorBookingDetail />}
              />
              <Route
                path="/my-booking-details"
                element={<VendorBookingDetail />}
              />

              <Route
                path="/my-booking-package-details"
                element={<PackageBookingDetail />}
              />

              <Route
                path="/booking/payment"
                element={<SuccessBookingService />}
              />

              <Route
                path="/booking/package-payment"
                element={<TaxiPackageSuccess />}
              />

              <Route
                path="/flight-booking-history"
                element={<FlightBookingHistory />}
              />
              <Route
                path="/hotel-booking-history"
                element={<HotelBookingHistory />}
              />
              <Route
                path="/hotel-booking-detail"
                element={<HotelBookingDetail />}
              />
              <Route
                path="/hotel-book-review-card"
                element={<HotelBookReviewCard />}
              />
              <Route
                path="/newsfeed-details/:id"
                element={<NewsfeedDetail />}
              />

              <Route path="/cancel-booking" element={<BookingCancellation />} />
              <Route
                path="/flight-cancel-booking-result"
                element={<FlightCancelResult />}
              />
              <Route
                path="/hotel-cancel-booking-result"
                element={<HotelCancelResult />}
              />

              <Route path="/cancel-status" element={<CancellationStatus />} />
              <Route path="/verify_email/:token" element={<VerifyEmail />} />
              <Route
                path="/flight-listings/:route"
                element={<FlightListing />}
              />

              <Route path="/flight-listings" element={<FlightListing />} />
              <Route path="/flight-detail" element={<FlightDetail />} />
              <Route
                path="/flight-booking-payment/response"
                element={<FlightPayment />}
              />
              <Route path="/hotel-listing" element={<HotelListing />} />
              <Route
                path="/hotel-listing/:cityname"
                element={<HotelListing />}
              />
              <Route path="/hotel-detail" element={<HotelDetail />} />
              <Route path="/hotel-redirect" element={<HotelRedirect />} />
              <Route
                path="/uniquehome-redirect"
                element={<UniqueHomeRedirect />}
              />

              <Route
                path="/hotel-booking-payment/response"
                element={<HotelPayment />}
              />
              <Route path="/unique-home/" element={<UniqueHomeListing />} />
              <Route path="/taxi-package/" element={<TaxiPackageListing />} />
              <Route path="/my-transport-package/" element={<TaxiPackageBookingListing />} />

              <Route
                path="/unique-home/:cityname"
                element={<UniqueHomeListing />}
              />

              <Route
                path="/unique-home/:cityname/:isscan"
                element={<UniqueHomeListing />}
              />

              <Route path="/tour" element={<TourListing />} />
              <Route path="/tour/:cityname" element={<TourListing />} />

              <Route path="/taxi" element={<TaxiListing />} />
              <Route path="/taxi/:cityname" element={<TaxiListing />} />

              <Route
                path="/multi-services"
                element={<MultiServicesListing />}
              />
              <Route
                path="/multi-services/:cityname"
                element={<MultiServicesListing />}
              />
              <Route
                path="/multi-services/:cityname/:categoryname"
                element={<MultiServicesListing />}
              />

              <Route path="/service-listings" element={<SearchResult />} />
              <Route
                path="/service-description/:title/:id/"
                element={<SearchDetail />}
              />
              <Route path="/book-service" element={<BookService />} />

              <Route path="/tour" element={<TourListing />} />
              <Route path="/taxi" element={<TaxiListing />} />
              <Route
                path="/multi-services"
                element={<MultiServicesListing />}
              />
              <Route path="/service-listings" element={<SearchResult />} />
              <Route
                path="/service-description/:title/:id/"
                element={<SearchDetail />}
              />
              <Route path="/book-service" element={<BookService />} />

              <Route
                path="/trip_planning_search"
                element={<TripPlanningSearch />}
              />
              <Route
                path="/trip_planning/:title/"
                element={<TripPlanningDetail />}
              />
              <Route
                path="/flight-booking-detail"
                element={<FlightBookingDetail />}
              />
              <Route
                path="/get-flight-booking-detail/:pnr/:booking_id"
                element={<GetFlightBookingDetail />}
              />

              <Route
                path="/trip_planning_flight_search/:trip_id"
                element={<FlightTripSearch />}
              />

              <Route
                path="/BookingUniqueHomeListing"
                element={<BookingUniqueHomeListing />}
              />

              <Route path="trip-detail" element={<TripDetail />} />


              <Route element={<UserAuth redirectPath="/" />}>
                <Route path="member" element={<MemberPanel />}>
                  <Route path="newsfeed" element={<Newsfeed />} />

                  <Route path="mypost" element={<Newsfeed />} />

                  <Route path="all-bookings" element={<AllBookings />} />
                  <Route path="trip" element={<ManageTrip />} />

                  <Route path="flight-bookings" element={<FlightBookings />} />
                  <Route path="hotel-bookings" element={<HotelBookings />} />
                  <Route
                    path="wishlist-services"
                    element={<WishlistServices />}
                  />
                  <Route path="buy-coin" element={<BuyCoin />} />
                  <Route path="transfer-coins" element={<TransferCoins />} />
                  <Route path="redeem-coins" element={<RedeemCoins />} />
                  <Route
                    path="view-redeem-coin-requests"
                    element={<ViewRedeemCoinRequests />}
                  />
                  <Route
                    path="wallet-transactions"
                    element={<WalletTransactions />}
                  />

                  <Route path="business-chain" element={<BusinessChain />} />
                  <Route path="my-chain" element={<BusinessMyChain />} />
                  <Route
                    path="create-business-group"
                    element={<CreateBusinessGroup />}
                  />

                  <Route path="all-members" element={<AllMembers />} />
                  <Route path="followers" element={<Followers />} />
                  <Route path="following" element={<Following />} />
                  <Route path="follow-requests" element={<FollowRequests />} />

                  <Route path="notification" element={<Notification />} />

                  <Route path="profile" element={<Profile />} />
                  <Route path="refer-earn" element={<ReferEarn />} />
                  <Route path="change-email" element={<ChangeEmail />} />
                  <Route path="change-password" element={<ChangePassword />} />
                  <Route
                    path="change-phone-number"
                    element={<ChangePhoneNumber />}
                  />

                  <Route element={<VendorAuth redirectPath="/" />}>
                    <Route
                      path="offline-booking"
                      element={<OfflineBooking />}
                    />
                    <Route
                      path="offline-booking-listing"
                      element={<OfflineListing />}
                    />
                    <Route
                      path="vendor-received-orders"
                      element={<VendorReceivedOrders />}
                    />
                    <Route path="all-listings" element={<AllListings />} />

                    <Route
                      path="manage-unique-homes"
                      element={<ManageUniqueHomes />}
                    />
                    <Route path="manage-taxi" element={<ManageTaxi />} />
                    <Route path="manage-tour" element={<ManageTour />} />
                    <Route
                      path="manage-multi-services"
                      element={<ManageMultiServices />}
                    />

                    <Route path="add-unique-home" element={<AddUniqueHome />} />
                    <Route
                      path="add-unique-home-two"
                      element={<AddUniqueHomeTwo />}
                    />
                    <Route
                      path="add-service-list-two"
                      element={<AddServiceListTwo />}
                    />
                    <Route
                      path="add-service-list-three"
                      element={<AddServiceListThree />}
                    />
                    <Route
                      path="add-fixed-departure-tour"
                      element={<AddFixedDepartureTour />}
                    />
                    <Route
                      path="add-private-tour"
                      element={<AddPrivateTour />}
                    />
                    <Route path="add-taxi" element={<AddTaxi />} />
                    <Route
                      path="add-taxi-documents"
                      element={<AddTaxiDocuments />}
                    />

                    <Route
                      path="add-multi-services"
                      element={<AddMultiServices />}
                    />
                    <Route
                      path="add-multi-services-category"
                      element={<AddMultiServicesCategory />}
                    />


                    <Route
                      path="calendar-management-unique-homes"
                      element={<CalendarManagementUniqueHomes />}
                    />
                    <Route
                      path="calendar-management-tour"
                      element={<CalendarManagementTour />}
                    />
                    <Route
                      path="calendar-management-multi-services"
                      element={<CalendarManagementMultiServices />}
                    />
                  </Route>
                </Route>
                <Route path="member" element={<Outlet />}>
                  <Route
                    path="view_user_profile/:id"
                    element={<ViewUserProfile />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </main>
        {/* <Footer /> */}
      </Suspense>
    </>
  );
};

export default Routing;
